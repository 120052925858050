<template>
  <div class="icon play-icon">
    <svg
      width="18"
      height="18"
      viewBox="0 0 18 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M1.75342 2.44127V15.5588C1.7534 15.7533 1.80381 15.9445 1.89973 16.1137C1.99565 16.2829 2.1338 16.4243 2.3007 16.5242C2.4676 16.6241 2.65755 16.679 2.852 16.6836C3.04645 16.6881 3.23876 16.6422 3.41017 16.5503L15.6524 9.99151C15.8318 9.89537 15.9817 9.75238 16.0862 9.57778C16.1907 9.40318 16.2459 9.2035 16.2459 9.00002C16.2459 8.79653 16.1907 8.59685 16.0862 8.42225C15.9817 8.24765 15.8318 8.10466 15.6524 8.00852L3.41017 1.44977C3.23876 1.35783 3.04645 1.3119 2.852 1.31647C2.65755 1.32104 2.4676 1.37594 2.3007 1.47583C2.1338 1.57571 1.99565 1.71716 1.89973 1.88637C1.80381 2.05557 1.7534 2.24676 1.75342 2.44127Z"
        stroke="white"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  </div>
</template>

<style lang="scss" scoped>
.play-icon {
  height: 100%;
  width: 100%;
  svg {
    height: 100%;
    width: 100%;
  }
}
</style>
