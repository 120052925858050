<template>
  <div class="mute-icon">
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M11 16.88L14.06 18.83C14.2829 18.9971 14.5478 19.0989 14.8253 19.1239C15.1027 19.149 15.3817 19.0962 15.6308 18.9716C15.88 18.8471 16.0895 18.6556 16.236 18.4186C16.3824 18.1816 16.46 17.9086 16.46 17.63V13"
        stroke="white"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M9.3 14.63H4.5C4.10218 14.63 3.72064 14.472 3.43934 14.1907C3.15804 13.9094 3 13.5278 3 13.13V10.13C3 9.7322 3.15804 9.35067 3.43934 9.06936C3.72064 8.78806 4.10218 8.63002 4.5 8.63002H7.5L14.1 4.42002C14.3235 4.25236 14.5895 4.15047 14.8678 4.12584C15.1461 4.1012 15.4258 4.1548 15.6753 4.28059C15.9248 4.40639 16.1342 4.59937 16.2799 4.8378C16.4257 5.07622 16.5019 5.3506 16.5 5.63002V8.63002"
        stroke="white"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M3 19.87L21 4.87"
        stroke="white"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  </div>
</template>

<style lang="scss" scoped>
.mute-icon {
  height: 100%;
  width: 100%;
  svg {
    height: 100%;
    width: 100%;
  }
}
</style>
