<template>
  <div class="icon pause-icon">
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M5.625 3.125H6.875C6.875 3.125 8.125 3.125 8.125 4.375V15.625C8.125 15.625 8.125 16.875 6.875 16.875H5.625C5.625 16.875 4.375 16.875 4.375 15.625V4.375C4.375 4.375 4.375 3.125 5.625 3.125Z"
        stroke="white"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M13.125 3.125H14.375C14.375 3.125 15.625 3.125 15.625 4.375V15.625C15.625 15.625 15.625 16.875 14.375 16.875H13.125C13.125 16.875 11.875 16.875 11.875 15.625V4.375C11.875 4.375 11.875 3.125 13.125 3.125Z"
        stroke="white"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  </div>
</template>

<style lang="scss" scoped>
.pause-icon {
  height: 100%;
  width: 100%;
  svg {
    height: 100%;
    width: 100%;
  }
}
</style>
