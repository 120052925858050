<template>
  <div class="unmute-icon">
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M14.171 4.65803C13.9218 4.53346 13.6428 4.48077 13.3653 4.50585C13.0878 4.53093 12.8228 4.63279 12.6 4.80003L6 9.00003H3C2.60218 9.00003 2.22064 9.15806 1.93934 9.43937C1.65804 9.72067 1.5 10.1022 1.5 10.5V13.5C1.5 13.8979 1.65804 14.2794 1.93934 14.5607C2.22064 14.842 2.60218 15 3 15H6L12.6 19.2C12.8229 19.3672 13.0878 19.4689 13.3653 19.494C13.6427 19.519 13.9217 19.4662 14.1708 19.3417C14.42 19.2171 14.6295 19.0256 14.776 18.7886C14.9224 18.5517 15 18.2786 15 18V6.00003C15.0001 5.72142 14.9226 5.44829 14.7761 5.21126C14.6297 4.97423 14.4202 4.78266 14.171 4.65803Z"
        stroke="white"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M21.4629 15.75C22.1395 14.6896 22.4989 13.4579 22.4989 12.2C22.4989 10.9421 22.1395 9.71045 21.4629 8.65002"
        stroke="white"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M18.562 14.441C19.0678 13.8184 19.3439 13.0407 19.3439 12.2385C19.3439 11.4363 19.0678 10.6586 18.562 10.036"
        stroke="white"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M6 9V15"
        stroke="white"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  </div>
</template>

<style lang="scss" scoped>
.unmute-icon {
  height: 100%;
  width: 100%;
  svg {
    height: 100%;
    width: 100%;
  }
}
</style>
